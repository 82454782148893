@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

.ant-btn-primary {
  background-color: #00359c;
  border-color: #00359c;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #00359c;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #00359c;
  border-color: #00359c;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #00359c;
}
.ant-tabs-ink-bar {
  background-color: #00359c;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #00359c;
}

.ant-input:focus {
  border-color: #00359c;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #00359c;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00359c;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00359c;
  border-color: #00359c;
}
.ant-tooltip-inner {
  color: #000000;
  background-color: #f6f6f6;
  white-space: pre-wrap;
}
.ant-tooltip-arrow:before {
  background-color: #f6f6f6;
}

.ant-input:hover {
  border-color: #00359c;
}

.ant-timeline-item-head {
  background-color: #f4f4f4;
}
